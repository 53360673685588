body {
  font-family: -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  padding: 0 1rem 1rem 1rem;
  max-width: 680px;
  margin: 0 auto;
  background: #fff;
  color: #333;
}

li,
p {
  line-height: 1.5rem;
}

a {
  font-weight: 500;
}

hr {
  border: 1px solid #ddd;
}


/*
https://github.com/chakra-ui/chakra-ui/issues/580#issuecomment-653527951

*/

.react-datepicker-wrapper {
  border: 1px solid;
}


